import React, { Fragment, Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { translate } from "react-i18next";
import { compose } from "recompose";
import { get as g, isEmpty, uniqBy } from "lodash";

import { be, bm } from "../../utils/bliss";
import "./projectCosts.css";

import EnumsDuck from "../../redux/ducks/enums";
import { formatPrice, isMobile } from "../../utils/utils";
import Headline from "../headline/headline";
import Text from "../text/text";
import Info from "../info/info";
import settingsService from "../../services/settingsService";

const MODULE_NAME = "ProjectCosts";

class ProjectCosts extends Component {
  constructor(props) {
    super(props);

    const showColleagues = !!g(props, "project.handshakes", []).find(
      hs => hs.state === g(props, "handshakeStates.accepted.name", "")
    );
    let modulesToShow = [];

    if (!isEmpty(g(props, "project.basePrice", {}))) {
      modulesToShow = [...modulesToShow, "basePrice", "others"];
    }

    if (showColleagues) {
      modulesToShow.push("colleagues");
    }

    if (isMobile()) {
      modulesToShow = [];
    }

    this.state = {
      modulesToShow,
      deposit: null
    };
  }

  componentDidMount() {
    settingsService.getDepositSettings().then(settings => this.setState({ deposit: settings }));
  }

  getColleagues(acceptedHandshakes = []) {
    const { data } = this.props;

    return uniqBy([...g(data, "required", []), ...g(data, "all", [])], "id").map(subrole => {
      const contract = acceptedHandshakes.find(hs => g(hs, "subrole.id", "") === subrole.id);
      const show = !!contract || !!g(data, "required", []).find(req => g(req, "id", "") === subrole.id);

      return show ? (
        <Fragment key={subrole.id}>
          <div className="row">
            <div className="col-sm">{subrole.name}</div>
            <div className="col-sm-auto">
              <Text small>
                {" "}
                {contract ? (
                  <Fragment>
                    <b>{formatPrice(contract.price)}</b> Kč
                  </Fragment>
                ) : (
                  "-"
                )}
              </Text>
            </div>
          </div>
        </Fragment>
      ) : null;
    });
  }

  getOtherCosts() {
    const { project } = this.props;

    return (
      <Fragment>
        <div className="row">
          <div className="col-sm">
            <Info className="pr-2">
              Tým Pointy dělá všechno pro to, aby vaše vydavatelská zkušenost byla co nejpříjemnější. Pointa provize je
              odměna pro nás.
            </Info>
            Pointa provize
          </div>
          <div className="col-sm-auto">
            <Text small>
              <b>{formatPrice(g(project, "financing.entryFee", 0))}</b> Kč
            </Text>
          </div>
        </div>
        <div className="row">
          <div className="col-sm">
            <Info className="pr-2">
              Celý neprodaný náklad vaší knihy skladujeme po dobu jednoho roku. Tato položka zajistí, že vaše knihy
              budou uschovány v zabezpečeném skladu.
            </Info>
            Poplatky za skladování
          </div>
          <div className="col-sm-auto">
            <Text small>
              <b>{formatPrice(g(project, "financing.warehouseCosts", 0))}</b> Kč
            </Text>
          </div>
        </div>
        <div className="row">
          <div className="col-sm">
            <Info className="pr-2">
              Přesto, že v kalkulačce počítáme s nejrůznějšími náklady, vždy může nastat situace, kterou jsme ani my ani
              vy nepředpokládali. A proto do ní zahrnujeme i nepředpokládané náklady.
            </Info>
            Nepředpokládané náklady
          </div>
          <div className="col-sm-auto">
            <Text small>
              <b>{formatPrice(g(project, "financing.buffer", 0))}</b> Kč
            </Text>
          </div>
        </div>
        <div className="row">
          <div className="col-sm">
            <Info className="pr-2">
              Díky předprodeji můžete získat peníze na vydání knihy. Nesmíme však zapomínat na poplatky za použití
              platební brány.
            </Info>
            Poplatky za platební bránu
          </div>
          <div className="col-sm-auto">
            <Text small>
              <b>{formatPrice(g(project, "financing.pgwFee", 0))}</b> Kč
            </Text>
          </div>
        </div>
        {/* <div className="row"> */}
        {/* <div className='col-sm'> */}
        {/* <Info className="pr-2">I my bohužel musíme zaplatit daně za hmotné zboží a služby, bez kterých vaše kniha nevznikne. Tato částka tyto výdaje pokrývá.</Info> */}
        {/* Daně na služby */}
        {/* </div> */}
        {/* <div className='col-sm-auto'> */}
        {/* <Text small><b>{formatPrice(g(project, 'financing.vatSafety', 0))}</b> Kč</Text> */}
        {/* </div> */}
        {/* </div> */}
      </Fragment>
    );
  }

  toggleModule = (module = "") => {
    const { modulesToShow } = this.state;
    this.setState({
      modulesToShow: modulesToShow.includes(module)
        ? modulesToShow.filter(m => m !== module)
        : [...modulesToShow, module]
    });
  };

  getState(module = "") {
    if (this.state.modulesToShow.includes(module)) {
      return "up";
    }
    return "down";
  }

  render() {
    const { project = {}, t, handshakeStates } = this.props;
    const { modulesToShow } = this.state;
    const { acceptedHandshakes, totalPrice } = g(project, "handshakes", []).reduce(
      (res, hs) => {
        if (g(handshakeStates, "accepted.name", "") === hs.state) {
          return {
            ...res,
            acceptedHandshakes: [...res.acceptedHandshakes, hs],
            totalPrice: res.totalPrice + hs.price
          };
        }
        return res;
      },
      {
        acceptedHandshakes: [],
        totalPrice: 0
      }
    );

    const depositAmount = this.state.deposit && this.state.deposit.amount ? this.state.deposit.amount : null;

    return (
      <div className={bm(MODULE_NAME)}>
        <div className={be(MODULE_NAME, "title")}>
          <Headline bold component="h2" xs>
            {t("projectDetail.costTitle")}
          </Headline>
        </div>
        <div onClick={() => this.toggleModule("basePrice")}>
          <Headline component="h3" price primary uppercase xxs>
            NÁKLADY NA TISK{" "}
            <img className={be(MODULE_NAME, "arrow", this.getState("basePrice"))} src="/images/svg/arrow_up.svg" />
          </Headline>
        </div>
        {modulesToShow.includes("basePrice") && (
          <div className="row">
            <div className="col-sm">
              <Info className="pr-2">
                Tahle cena pokrývá tisk vámi vybrané knihy, včetně dopravy z tiskárny do skladů Pointy.
              </Info>
              Tisk ve vybraném objemu
            </div>
            <div className="col-sm-auto">
              <Text small>
                <b>{formatPrice(g(project, "financing.basePrice", 0))}</b> Kč
              </Text>
            </div>
          </div>
        )}
        <hr />
        <div className={be(MODULE_NAME, "colleagues")} onClick={() => this.toggleModule("colleagues")}>
          <Headline component="h3" price primary uppercase xxs>
            KOLEGOVÉ
            <img className={be(MODULE_NAME, "arrow", this.getState("colleagues"))} src="/images/svg/arrow_up.svg" />
          </Headline>
        </div>
        {modulesToShow.includes("colleagues") && this.getColleagues(acceptedHandshakes)}
        <Text light small xs>
          Bez provize Pointa.cz <strong>{formatPrice(0.12 * totalPrice)}</strong> Kč (12%)
        </Text>
        <hr />
        <div className={be(MODULE_NAME, "others")} onClick={() => this.toggleModule("others")}>
          <Headline component="h3" price primary uppercase xxs>
            DALŠÍ NÁKLADY{" "}
            <img className={be(MODULE_NAME, "arrow", this.getState("others"))} src="/images/svg/arrow_up.svg" />
          </Headline>
        </div>
        {modulesToShow.includes("others") && this.getOtherCosts(acceptedHandshakes)}

        <div className={be(MODULE_NAME, "price")}>
          <div className="row">
            <div className="col-sm-2 d-flex flex-row">
              <Info className="pr-2">
                Jde o souhrn nákladů na rozsah prací, které jste si s jednotlivými kolegy domluvili. My jsme si přidali
                12% poplatek z této částky za to zprostředkování.
              </Info>
              <Text small>Celkové náklady</Text>
            </div>
            <div className="col-sm-10">
              <div className="text-right">
                <Text bold primary small>{`${formatPrice(g(project, "financing.total", 0))} Kč`}</Text>
                <br />
                {/* <Text light xs small>Včetně DPH <strong>{formatPrice(g(project, 'financing.vat', 0))}</strong> Kč (15%)</Text> */}
                {/* <Info className="pl-2">Za knihy, které spolu prodáme, musíme zaplatit DPH. Proto na něj nesmíme zapomínat už při předprodeji.</Info> */}
              </div>
            </div>
          </div>
        </div>

        {depositAmount !== null && (
          <Fragment>
            <hr />
            <div style={{ marginTop: 20, marginBottom: 12, textAlign: "center" }}>
              <Headline bold component="h2" xs>
                Záloha za uskutečnění předprodeje
              </Headline>
            </div>
            <div className="row">
              <div className="col-sm">
                <Info className="pr-2">
                  Vratná záloha nám umožní pokrýt část nákladů spojených s přípravou předprodeje v případě, že by
                  náhodou předprodej nevyšel. Ale nebojte, pokud bude předprodej úspěšný, celá částka vám bude vrácena
                  zpět.
                </Info>
                Záloha
              </div>
              <div className="col-sm-auto mb-3">
                <Text bold primary small>
                  <b>{formatPrice(depositAmount)}</b> Kč
                </Text>
              </div>
            </div>
            <div>
              <Text light small xs>
                Více informací o záloze{" "}
                <a href="https://blog.pointa.cz/zaloha/" rel="noreferrer" target="_blank">
                  zde
                </a>
              </Text>
            </div>
          </Fragment>
        )}
      </div>
    );
  }
}

ProjectCosts.propTypes = {
  data: PropTypes.object.isRequired,
  handshakeStates: PropTypes.object.isRequired,
  project: PropTypes.object,
  t: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  handshakeStates: EnumsDuck.getHandhakeStates(state)
});

export default compose(translate("translations"), connect(mapStateToProps))(ProjectCosts);
