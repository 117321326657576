import React, { Fragment } from "react";
import { isEmpty, get as g } from "lodash";
import moment from "moment";
import { connect } from "react-redux";
import { translate } from "react-i18next";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";

import { PREVIEWS_API } from "../../../../services/config";

import { be } from "../../../../utils/bliss";

import EnumsDuck from "../../../../redux/ducks/enums";
import UserDuck from "../../../../redux/ducks/user";
import WizardDuck from "../../../../redux/ducks/wizard";
import UIDuck from "../../../../redux/ducks/ui";

import Headline from "../../../../components/headline/headline";
import Tag from "../../../../components/tags/tag/tag";
import Text from "../../../../components/text/text";
import UploadFile from "../../../../components/input/uploadFile/uploadFile";
import UserCard from "../../../../components/userCard/userCard";

import AuthorInfo from "./authorInfo";
import ShowInfoTable from "./showInfoTable";

const MODULE_NAME = "ProjectDetailScreen";

const ProposalState = ({ dispatch, project, projectStates, t, user, history }) => {
  const isUser = !isEmpty(user);

  const deleteProject = () => {
    dispatch(
      UIDuck.openModal(UIDuck.modalTypes.alertPrompt, {
        text: t("projectDetail.deleteProjText"),
        callback: () => {
          dispatch(WizardDuck.deleteProject(project));
        }
      })
    );
  };

  const editProject = () => {
    dispatch(WizardDuck.editProject(project));
    history.push("/wizard");
  };

  const toggleVisibility = () => {
    if (isEmpty(g(project, "handshakes", [])) && g(project, "state", "") === g(projectStates, "proposal.name", "")) {
      dispatch(WizardDuck.toggleVisibility(project)).then(updatedProject => {
        window.alertify.success(`Projekt je nyní ${updatedProject.isVisible ? "viditelný" : "neviditelný"}.`);
      });
    } else {
      window.alertify.error("Projekt není ve stavu, kdy můžete měnit jeho visibilitu.");
    }
  };

  const showUser = userId => {
    history.push(`/profile/${userId}`);
  };

  return (
    <div className="row">
      <div className="col-md-8">
        <Headline page>{project.name}</Headline>
        <div className={be(MODULE_NAME, "spacing", "sm")} />
        <div className={be(MODULE_NAME, "genres")}>
          {g(project, "genres", [])
            .filter(gen => !isEmpty(gen))
            .map((genre, i) => (
              <div key={genre.id} className={be(MODULE_NAME, "genre")}>
                <Text bold light>
                  {genre.name}
                  {i === project.genres.length - 1 ? "" : ","}{" "}
                </Text>
              </div>
            ))}
        </div>
        {project.isEditable && (
          <div className={be(MODULE_NAME, "activity")}>
            <div className={be(MODULE_NAME, "spacing", "sm")} />
            <div className="row">
              <div className="col-sm-auto">
                <button className={be(MODULE_NAME, "activityButton")} onClick={editProject} type="button">
                  <img alt="edit" className={be(MODULE_NAME, "btnIcon")} src="/images/svg/edit.svg" />
                  {t("projectDetail.edit")}
                </button>
              </div>
              {isEmpty(project.acceptedHandshakes) && (
                <div className="col-sm-auto">
                  <button className={be(MODULE_NAME, "activityButton")} onClick={deleteProject} type="button">
                    <img alt="delete" className={be(MODULE_NAME, "btnIcon")} src="/images/svg/trash.svg" />
                    {t("projectDetail.cancelProject")}
                  </button>
                </div>
              )}
              {isEmpty(g(project, "handshakes", [])) &&
                g(project, "state", "") === g(projectStates, "proposal.name", "") && (
                  <div className="col-sm-auto">
                    <button className={be(MODULE_NAME, "activityButton")} onClick={toggleVisibility} type="button">
                      <span className={be(MODULE_NAME, "btnIcon")}>
                        <i
                          className="fa fa-eye"
                          style={{
                            color: "#DC573E"
                          }}
                        />
                      </span>
                      {project.isVisible ? "Skrýt projekt" : "Zveřejnit projekt"}
                    </button>
                  </div>
                )}
            </div>
            <div className={be(MODULE_NAME, "spacing", "lg")} />
          </div>
        )}
        {!isEmpty(g(project, "tags", [])) && (
          <Fragment>
            <Headline component="h3" light uppercase xxs>
              Štítky
            </Headline>
            <div className={be(MODULE_NAME, "tags")}>
              {g(project, "tags", [])
                .filter(tag => !isEmpty(tag))
                .map(tag => (
                  <div key={tag.id} className={be(MODULE_NAME, "tag")}>
                    <Tag key={tag.id} {...tag} />
                  </div>
                ))}
            </div>
            <div className={be(MODULE_NAME, "spacing", "sm")} />
          </Fragment>
        )}
        <div className={be(MODULE_NAME, "spacing", "md")} />
        {project.annotation && (
          <Fragment>
            {/* <Headline component="h3" light uppercase xxs>
                              Anotace
                          </Headline> */}
            <div className={be(MODULE_NAME, "spacing", "sm")} />
            {project.type === "financing" ? (
              <Text greenReward>{project.annotation}</Text>
            ) : (
              <div className={be(MODULE_NAME, "annotation")}>{project.annotation}</div>
            )}
            <div className={be(MODULE_NAME, "spacing", "lg")} />
          </Fragment>
        )}

        <ShowInfoTable project={project} showOnMobile />
        <div className={be(MODULE_NAME, "spacing", "lg")} />

        <AuthorInfo project={project} />

        {project.isPublicProject && !isEmpty(project.acceptedHandshakes) && (
          // TODO: coding stuff
          <Fragment>
            <div className={be(MODULE_NAME, "authorTitle")}>
              {`KOLEGOVÉ ${
                g(project, "state", "") === g(projectStates, "funding.name", "")
                  ? "čekající na úspěšné financování"
                  : ""
              }`}
            </div>
            <div className="row">
              {Object.keys(g(project, "acceptedContributors", {})).map(key => {
                const contributor = g(project, `acceptedContributors.${key}`, {});
                return (
                  <div
                    key={`${key}_${contributor.id}_userCard`}
                    className={`col-12 col-sm-6${
                      Object.keys(g(project, "acceptedContributors", {})).length > 3 ? " col-lg-3" : " col-lg"
                    }`}
                  >
                    {!isUser && (
                      <UserCard
                        description={key.toUpperCase()}
                        picture={contributor.picture}
                        userName={contributor.name}
                      />
                    )}
                    {isUser && (
                      <UserCard
                        dark
                        description={key.toUpperCase()}
                        hover
                        onClick={() => showUser(contributor.id)}
                        picture={contributor.picture}
                        pointer
                        userName={contributor.name}
                      />
                    )}
                  </div>
                );
              })}
            </div>
            <div className={be(MODULE_NAME, "spacing", "lg")} />
          </Fragment>
        )}

        {!isEmpty(project.sampleFiles) && (
          <Fragment>
            <div className={be(MODULE_NAME, "spacing", "lg")} />
            <div className="row">
              <div className="col">
                <div className={be(MODULE_NAME, "samples")}>
                  <Headline bold component="h2" pageSub>
                    Přečtěte si ukázku
                  </Headline>
                  <div className={be(MODULE_NAME, "spacing", "lg")} />
                  <UploadFile
                    disableManual
                    editable={false}
                    samples
                    value={project.sampleFiles.map(file => ({
                      ...file,
                      data: `${PREVIEWS_API}${file.data}`
                    }))}
                  />
                </div>
              </div>
            </div>
            <div className={be(MODULE_NAME, "spacing", "lg")} />
            {/* <div className="row mt-2">
                              <div className="col-sm-6">
                                  <Button black>
                                      <img src="/images/svg/download.svg" />{" "}
                                      Stáhnout vše
                                  </Button>
                              </div>
                          </div>
                          */}
          </Fragment>
        )}

        {/* <div>
          <h2 className="Headline Headline--pageSub Headline--bold py-5">Bibliografické údaje</h2>
          <div className="row">
            <div className="col-md offset-lg-1 ml-0">
              <div className="ProjectPrices">
                <div className="row">
                  <div className="col-sm pull-left">
                    <Text bold>Název</Text>
                  </div>
                  <div className="col-sm pull-right">{project.name}</div>
                </div>
                <div className={be(MODULE_NAME, 'spacing', 'xs', 'd-sm-block d-md-none')} />
                <div className="row">
                  <div className="col-sm pull-left">
                    <Text bold>Autor</Text>
                  </div>
                  <div className="col-sm pull-right">
                    {g(project, 'author', []).map((author) => (
                      <div key={author.id}>
                        {author.name} {author.surname}
                      </div>
                    ))}
                  </div>
                </div>
                <div className={be(MODULE_NAME, 'spacing', 'xs', 'd-sm-block d-md-none')} />
                {project.bibliographyEan ? (
                  <div className="row">
                    <div className="col-sm pull-left">
                      <Text bold>EAN</Text>
                    </div>
                    <div className="col-sm pull-right">{project.bibliographyEan}</div>
                  </div>
                ) : (
                  ''
                )}
                <div className={be(MODULE_NAME, 'spacing', 'xs', 'd-sm-block d-md-none')} />
                {project.bibliographyIsbn ? (
                  <div className="row">
                    <div className="col-sm pull-left">
                      <Text bold>ISBN</Text>
                    </div>
                    <div className="col-sm pull-right">{project.bibliographyIsbn}</div>
                  </div>
                ) : (
                  ''
                )}
                <div className={be(MODULE_NAME, 'spacing', 'xs', 'd-sm-block d-md-none')} />
                {project.numberOfPages ? (
                  <div className="row">
                    <div className="col-sm pull-left">
                      <Text bold>Počet stran</Text>
                    </div>
                    <div className="col-sm pull-right">{project.numberOfPages}</div>
                  </div>
                ) : (
                  ''
                )}
                <div className={be(MODULE_NAME, 'spacing', 'xs', 'd-sm-block d-md-none')} />
                {project.bibliographyFormat ? (
                  <div className="row">
                    <div className="col-sm pull-left">
                      <Text bold>Formát</Text>
                    </div>
                    <div className="col-sm pull-right">{project.bibliographyFormat}</div>
                  </div>
                ) : (
                  ''
                )}
                <div className={be(MODULE_NAME, 'spacing', 'xs', 'd-sm-block d-md-none')} />
                {project.bibliographyBindings ? (
                  <div className="row">
                    <div className="col-sm pull-left">
                      <Text bold>Vazba</Text>
                    </div>
                    <div className="col-sm pull-right">{project.bibliographyBindings}</div>
                  </div>
                ) : (
                  ''
                )}
                <div className={be(MODULE_NAME, 'spacing', 'xs', 'd-sm-block d-md-none')} />
                {project.bibliographyDimensions ? (
                  <div className="row">
                    <div className="col-sm pull-left">
                      <Text bold>Rozměry</Text>
                    </div>
                    <div className="col-sm pull-right">{project.bibliographyDimensions}</div>
                  </div>
                ) : (
                  ''
                )}
                <div className={be(MODULE_NAME, 'spacing', 'xs', 'd-sm-block d-md-none')} />
                {project.releaseDate ? (
                  <div className="row">
                    <div className="col-sm pull-left">
                      <Text bold>Datum vydání</Text>
                    </div>
                    <div className="col-sm pull-right">{moment(project.releaseDate).format('DD. MM. YYYY')}</div>
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        </div> */}

        {/* {isEditable && ( */}
        {/* <div> */}
        {/* <Headline component='h3' light uppercase xxs>{t('projectDetail.fbPage')}</Headline> */}
        {/* <div className={be(MODULE_NAME, 'spacing','xs')} /> */}
        {/* <FBbutton */}
        {/* label="Vytvořit stránku" */}
        {/* className="fb-button" */}
        {/* callback={createFacebookPage} */}
        {/* scope="public_profile,publish_pages,manage_pages" */}
        {/* /> */}
        {/* </div> */}
        {/* )} */}
        {/* <div className={be(MODULE_NAME, 'spacing','xs')} /> */}

        {/* {isEditable && <Headline component='h3' light uppercase xxs>{t('projectDetail.why')}</Headline>} */}
      </div>
      <ShowInfoTable project={project} showOnMobile={false} />
    </div>
  );
};

const mapStateToProps = state => ({
  user: UserDuck.getUser(state),
  projectStates: EnumsDuck.getProjectStates(state)
});

export default compose(withRouter, translate("translations"), connect(mapStateToProps))(ProposalState);
