import React from "react";
import { isEmpty, get as g, uniqBy } from "lodash";
import "./authorCard.css";
import { PREVIEWS_API } from "../../../../services/variables";
import { isMobile } from "../../../../utils/utils";
import { be } from "../../../../utils/bliss";
import MobileSlider from "../../../../components/mobileSlider/mobileSlider";
import Text from "../../../../components/text/text";
import Headline from "../../../../components/headline/headline";

const MODULE_NAME = "AuthorCard";

const AuthorCard = ({ reviews }) => {
  return (
    <div className={be(MODULE_NAME, "container")}>
      <MobileSlider
        responsive={[
          {
            breakpoint: 1900,
            infinite: false,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              arrows: true
            }
          },
          {
            breakpoint: 920,
            infinite: false,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 480,
            infinite: false,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]}
      >
        {reviews
          .filter(item => item.type === "authorReview")
          .sort((a, b) => g(a.item, "position") + g(b.item, "position"))
          .map((item, index) => (
            <div className={be(MODULE_NAME, "wrapper")} key={item.id}>
              <a href={item.url} style={{ textDecoration: "none" }}>
                <div className={be(MODULE_NAME, "photoSide")}>
                  <img
                    className={be(MODULE_NAME, "imgLeftPrimary")}
                    alt="blogPhoto"
                    src={`${PREVIEWS_API}${item.image}`}
                  />
                </div>
                <div className={be(MODULE_NAME, "info")}>
                  <div>
                    {isMobile() && (
                      <div>
                        <div className="mb-2" style={{ textAlign: "left" }}>
                          <Text small greenReward>
                            {item.name}
                          </Text>
                        </div>
                        <div className="mb-2" style={{ textAlign: "left" }}>
                          <Text small bold greenReward>
                            {item.role}
                          </Text>
                        </div>
                      </div>
                    )}
                    {!isMobile() && (
                      <div>
                        <div
                          className="mb-2"
                          style={{ display: "flex", justifyContent: "flex-start", textAlign: "left" }}
                        >
                          <Headline subheadline greenReward>
                            {item.name}
                          </Headline>
                        </div>
                        <div className="mb-3 pb-3" style={{ textAlign: "left" }}>
                          <Text small bold greenReward>
                            {item.role}
                          </Text>
                        </div>
                      </div>
                    )}
                  </div>
                  {!isMobile() && (
                    <div style={{ textAlign: "left" }}>
                      <Text small lighter greenReward>
                        {item.text.length > 290 ? item.text.substring(0, item.text.lastIndexOf(" ", 325)) : item.text}
                      </Text>
                    </div>
                  )}
                </div>
              </a>
            </div>
          ))}
      </MobileSlider>
    </div>
  );
};

export default AuthorCard;
