/* eslint-disable jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */
import React from "react";
import { get as g } from "lodash";

import { connect } from "react-redux";

import { be } from "../../../../utils/bliss";

import Headline from "../../../../components/headline/headline";
import Text from "../../../../components/text/text";

import WizardDuck from "../../../../redux/ducks/wizard";

const MODULE_NAME = "ProjectDetailScreen";

const EditableState = ({ project, dispatch }) => {
  const handleFinancing = async (funding = "") => {
    await dispatch(
      WizardDuck.patchProject(
        project.id,
        {
          funding,
          name: project.name
        },
        true
      )
    );
    window.alertify.success(
      `Projekt je nyní financován ${funding === "crowd" ? "formou příspěvků a předprodejem" : "vámi"}.`
    );
  };

  return (
    <div className="row">
      <div className="col">
        <div className={be(MODULE_NAME, "spacing", "xl")} />
        <Headline bold pageSub>
          Jak chcete vydání knihy financovat?
        </Headline>
        <div className="row">
          <div className="col-12">
            <div className={be(MODULE_NAME, "financing")}>
              <div
                className={be(
                  MODULE_NAME,
                  "financingItem",
                  `first ${g(project, "funding", "") === "crowd" ? "active" : ""}`
                )}
                onClick={() => handleFinancing("crowd")}
              >
                <Headline bold subheadline>
                  Předprodejem
                </Headline>
                <div className={be(MODULE_NAME, "spacing", "xs")} />
                <Text>Nechte si knihu financovat od čtenářů formou příspěvků a předprodejem.</Text>
              </div>
              <div
                className={be(MODULE_NAME, "financingItem", `${g(project, "funding", "") === "own" ? "active" : ""}`)}
                onClick={() => handleFinancing("own")}
              >
                <Headline bold subheadline>
                  Vlastními financemi
                </Headline>
                <div className={be(MODULE_NAME, "spacing", "xs")} />
                <div>
                  <Text>
                    Pro tento způsob financování nás prosím kontaktujte na{" "}
                    <a href="mailto:dikyvam@pointa.cz" onClick={e => e.stopPropagation()}>
                      dikyvam@pointa.cz
                    </a>{" "}
                    a uveďte název vašeho projektu.
                  </Text>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect()(EditableState);
