import React from "react";

import "./ourBenefits.css";

import { isMobile } from "../../../../utils/utils";
import { be } from "../../../../utils/bliss";
import Headline from "../../../../components/headline/headline";
import Text from "../../../../components/text/text";

const MODULE_NAME = "OurBenefits";

const OurBenefits = () => (
  <div style={{ background: "#ffffff", margin: "0 -1500px", padding: "50px 1500px", textAlign: "center" }}>
    <div className="mb-5 text-center">
      <Headline greenReward>10 výhod spolupráce s Pointou</Headline>
    </div>
    <div className="row">
      <div className={isMobile() ? "col-md-6 d-flex" : "col-md-6 d-flex justify-content-center"}>
        <div>
          <div className="d-flex align-items-center pb-4">
            <p className={be(MODULE_NAME, "number")}>1</p>
            <Text greenReward small left bold>
              Knihu můžeš vydat jednoduše online
            </Text>
          </div>
          <div className="d-flex align-items-center pb-4">
            <p className={be(MODULE_NAME, "number")}>2</p>
            <Text greenReward small left bold>
              Peníze na vydání vybíráš pomocí crowdfundingu
            </Text>
          </div>
          <div className="d-flex align-items-center pb-4">
            <p className={be(MODULE_NAME, "number")}>3</p>
            <Text greenReward small left bold>
              U nás si najdeš i vybereš všechny knižní kolegy
            </Text>
          </div>
          <div className="d-flex align-items-center pb-4">
            <p className={be(MODULE_NAME, "number")}>4</p>
            <Text greenReward small left bold>
              Najdeš u nás komunitu betačtenářů
            </Text>
          </div>
          <div className="d-flex align-items-center pb-4">
            <p className={be(MODULE_NAME, "number")}>5</p>
            <Text greenReward small left bold>
              Celková podoba knihy je jen ve tvých rukách
            </Text>
          </div>
        </div>
      </div>
      <div className={isMobile() ? "col-md-6 d-flex" : "col-md-6 d-flex justify-content-center"}>
        <div>
          <div className="d-flex align-items-center pb-4">
            <p className={be(MODULE_NAME, "number")}>6</p>
            <Text greenReward small left bold>
              Dostaneš vyšší provize a lepší podmínky
            </Text>
          </div>
          <div className="d-flex align-items-center pb-4">
            <p className={be(MODULE_NAME, "number")}>7</p>
            <Text greenReward small left bold>
              Vyřešíme za tebe smlouvy i další administrativu
            </Text>
          </div>
          <div className="d-flex align-items-center pb-4">
            <p className={be(MODULE_NAME, "number")}>8</p>
            <Text greenReward small left bold>
              Vypočítáme ti celkové náklady na knihu
            </Text>
          </div>
          <div className="d-flex align-items-center pb-4">
            <p className={be(MODULE_NAME, "number")}>9</p>
            <Text greenReward small left bold>
              Zajistíme tisk, ISBN a zaslání povinných výtisků
            </Text>
          </div>
          <div className="d-flex align-items-center pb-4">
            <p className={be(MODULE_NAME, "number")}>10</p>
            <Text greenReward small left bold>
              Distribuujeme do knihkupectví i <span style={{ whiteSpace: "nowrap" }}>e-shopů</span> v Česku i na
              Slovensku
            </Text>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default OurBenefits;
