import React from "react";
import { PropTypes as T } from "prop-types";
import { componentFromProp, defaultProps } from "recompose";
import { bm } from "../../utils/bliss";

import "./headline.css";

const MODULE_NAME = "Headline";

const enhance = defaultProps({ component: "span" });
const HeadlineComponent = enhance(componentFromProp("component"));

const Headline = ({
  hero,
  page,
  pageSub,
  pageSubSmall,
  subheadline,
  card,
  nowrap,
  component = "h1",
  children,
  center,
  primary,
  smallSemi,
  secondary,
  danger,
  green,
  semi,
  greenReward,
  spacingXs,
  fundingPage,
  inverted,
  blue,
  heroMobile,
  light,
  bold,
  small,
  xs,
  xxs,
  md,
  projects,
  dark,
  spacingSm,
  spacingSemi,
  semiMd,
  spacingMd,
  uppercase,
  price
}) => (
  <HeadlineComponent
    className={bm(MODULE_NAME, {
      hero,
      page,
      pageSub,
      subheadline,
      card,
      nowrap,
      spacingXs,
      smallSemi,
      center,
      primary,
      inverted,
      secondary,
      semiMd,
      pageSubSmall,
      spacingSemi,
      dark,
      heroMobile,
      danger,
      blue,
      semi,
      green,
      greenReward,
      fundingPage,
      light,
      price,
      bold,
      small,
      xs,
      xxs,
      md,
      spacingSm,
      spacingMd,
      uppercase
    })}
    component={component}
  >
    {children}
  </HeadlineComponent>
);

Headline.propTypes = {
  card: T.bool,
  hero: T.bool,
  page: T.bool,
  pageSub: T.bool,
  small: T.bool,
  subheadline: T.bool
};

export default Headline;
