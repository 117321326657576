import React from "react";

import { PREVIEWS_API } from "../../../../services/variables";

import "./reviews.css";
import { be } from "../../../../utils/bliss";
import Text from "../../../../components/text/text";

const MODULE_NAME = "Reviews";

const Reviews = ({ reviews, authors }) =>
  authors ? (
    <div className="row w-100 d-flex justify-content-between" style={{ margin: "0" }}>
      {reviews
        .filter(item => item.type === "authorContributorReview")
        .slice(0, 4)
        .map((item, key) => (
          <div className={(be(MODULE_NAME, "container"), "col-md-6 pb-5")} key={item.id}>
            <div className={be(MODULE_NAME, "wrapper")}>{item.text}</div>
            <div className={be(MODULE_NAME, "main")}>
              <div className={be(MODULE_NAME, "photoLeft")}>
                <img className={be(MODULE_NAME, "userPic")} src={`${PREVIEWS_API}${item.image}`} alt="userPic" />
              </div>
              <div className={be(MODULE_NAME, "userInfo")}>
                <div className={be(MODULE_NAME, "userName")}>
                  <Text bold greenReward small float>
                    {item.name}
                  </Text>
                </div>
                <div className={be(MODULE_NAME, "userRole")} style={{ textAlign: "left" }}>
                  <Text greenReward small float left>
                    {item.role}
                  </Text>
                </div>
              </div>
            </div>
          </div>
        ))}
    </div>
  ) : (
    <div className="row w-100 d-flex justify-content-between" style={{ margin: "0" }}>
      {reviews
        .filter(item => item.type === "contributorReview")
        .slice(0, 4)
        .map((item, key) => (
          <div className={(be(MODULE_NAME, "container"), "col-md-6 pb-5")} key={item.id}>
            <div className={be(MODULE_NAME, "wrapper")}>{item.text}</div>
            <div className={be(MODULE_NAME, "main")}>
              <div className={be(MODULE_NAME, "photoLeft")}>
                <img className={be(MODULE_NAME, "userPic")} src={`${PREVIEWS_API}${item.image}`} alt="userPic" />
              </div>
              <div className={be(MODULE_NAME, "userInfo")}>
                <div className={be(MODULE_NAME, "userName")}>
                  <Text bold greenReward small float>
                    {item.name}
                  </Text>
                </div>
                <div className={be(MODULE_NAME, "userRole")} style={{ textAlign: "left" }}>
                  <Text greenReward small float left>
                    {item.role}
                  </Text>
                </div>
              </div>
            </div>
          </div>
        ))}
    </div>
  );

export default Reviews;
