/* global dataLayer */
/* eslint-disable react/forbid-prop-types */
import React, { Component } from "react";
import Get from "restful-react";
import PropTypes from "prop-types";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import { translate } from "react-i18next";
import { compose } from "recompose";
import { get as g, isEmpty } from "lodash";

import Helmet from "react-helmet";

import { be } from "../../../utils/bliss";

import "./projectDetail.css";

import WizardDuck from "../../../redux/ducks/wizard";
import Footer from "../../../components/footer/footer";
import Button from "../../../components/button/button";
import UserDuck from "../../../redux/ducks/user";
import EnumsDuck from "../../../redux/ducks/enums";
import Loader from "../../../components/loader/loader";
import { API } from "../../../services/config";
import Header from "../../../components/header/header";
import Screen from "../../../components/screen/screen";
import StorageService from "../../../services/storageService";

import { dynamicTitle, getProjectDetailSubroles, isMobile, detectIE } from "../../../utils/utils";

import WizardService from "../../../services/wizardService";

import EditableState from "./components/editableState";
import FinancingState from "./components/financingState";
import NonFinancingState from "./components/nonFinancingState";
import FinancingStateRewards from "./components/financingStateRewards";
import ReleasedState from "./components/releasedState";
import ProgressState from "./components/progressState";
import ProposalState from "./components/proposalState";
import SuccessTopBar from "./components/successTopBar";
import Tabs from "./components/tabs";
import ShowModal from "./components/showModal";

import { SearchStorageService } from "../../../services/searchStorageService";

const MODULE_NAME = "ProjectDetailScreen";

class ProjectDetail extends Component {
  constructor(props) {
    super(props);
    const params = new URLSearchParams(props.location.search);
    const type = this.getType(props.project);
    this.state = {
      showHandshakeRequest: false,
      project: g(props, "project", {}),
      projectChecklistCompleted: g(props, "project.projectChecklist", [])
        .filter(pc => pc.deliveredAt)
        .map(pc => g(pc, "itemType.id", "")),
      loading: true,
      shouldShowSuccessTopBar: false,
      showOthers: isMobile()
        ? false
        : g(getProjectDetailSubroles(props.project, props.genres), "other", []).find(subrole =>
            g(props, `project.handshakesBySubroleId.${subrole.id}`, []).find(
              s => s.state === g(props, "handshakeStates.accepted.name", "")
            )
          )
    };
    this.myRef = React.createRef();
  }

  componentWillMount() {
    const {
      history,
      match: { params },
      dispatch,
      user,
      genres,
      handshakeStates
    } = this.props;

    if (StorageService.hasToken() && isEmpty(user)) {
      dispatch(UserDuck.setUserByToken("", true, false)).then(userObj => {
        dispatch(WizardDuck.setDetail(params.id, userObj.id))
          .then(project => {
            dispatch(WizardDuck.setDetailNews(project.id));
            this.setState({
              showOthers: isMobile()
                ? false
                : g(getProjectDetailSubroles(project, genres), "other", []).find(subrole =>
                    g(project, `handshakesBySubroleId.${subrole.id}`, []).find(
                      s => s.state === g(handshakeStates, "accepted.name", "")
                    )
                  ),
              projectChecklistCompleted: g(project, "projectChecklist", [])
                .filter(pc => pc.deliveredAt)
                .map(pc => g(pc, "itemType.id", ""))
            });
          })
          .catch(err => {
            if (g(err, "data.code", "") === 401) {
              history.push("/login");
              window.alertify.error("Prosím přihlaste se, vypršela platnost Vašeho tokenu!");
            } else {
              history.push("/not-allowed");
            }
          });
      });

      dispatch(EnumsDuck.setBindings());
      dispatch(EnumsDuck.loadCatalog());
      dispatch(EnumsDuck.setRoles());
    } else {
      dispatch(EnumsDuck.loadCatalog());
      dispatch(WizardDuck.setDetail(params.id, user.id, true))
        .then(project => {
          dispatch(WizardDuck.setDetailNews(project.id));
          this.setState({
            showOthers: isMobile()
              ? false
              : g(getProjectDetailSubroles(project, genres), "other", []).find(subrole =>
                  g(project, `handshakesBySubroleId.${subrole.id}`, []).find(
                    s => s.state === g(handshakeStates, "accepted.name", "")
                  )
                ),
            projectChecklistCompleted: g(project, "projectChecklist", [])
              .filter(pc => pc.deliveredAt)
              .map(pc => g(pc, "itemType.id", ""))
          });
        })
        .catch(err => {
          if (g(err, "response.data.code", "") === 401) {
            window.alertify.error("Prosím přihlaste se, vypršela platnost Vašeho tokenu!");
            history.push("/login");
          } else {
            history.push("/not-allowed");
          }
        });
    }
  }

  getType = ({ state, moonTarget }) => {
    const { projectStates } = this.props;
    switch (state) {
      case g(projectStates, "proposal.name", ""):
      default:
        return "making";

      case g(projectStates, "funding.name", ""):
        if (!moonTarget) return "waitingForPresale";
        return "financing";

      case g(projectStates, "failed.name", ""):
        return "failed";

      case g(projectStates, "inProgress.name", ""):
        return "inProgress";

      case g(projectStates, "released.name", ""):
        return "released";
    }
  };

  toggleShowSuccessTopBar = newState => {
    this.setState({
      shouldShowSuccessTopBar: newState
    });
  };

  followToggle = e => {
    e.preventDefault();
    e.stopPropagation();
    const { project, dispatch, detail, user } = this.props;

    if (project.followed) {
      dispatch(WizardDuck.unfollowProject(user.id, project.id, detail));
    } else {
      dispatch(WizardDuck.followProject(user.id, project.id, detail));
    }
  };

  scrollToMyRef = () => {
    this.preventListener = true;
    if (detectIE()) {
      setTimeout(() => {
        window.scrollTo(0, ReactDOM.findDOMNode(this.myRef.current).offsetTop);
      }, 0);
    } else {
      ReactDOM.findDOMNode(this.myRef.current).scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest"
      });
    }
    setTimeout(() => {
      this.preventListener = false;
    }, 1000);
  };

  render() {
    const { match, user, loading, location, projectStates, handshakeStates, history } = this.props;
    if (loading || isEmpty(projectStates || isEmpty(handshakeStates))) {
      return <Loader />;
    }

    return (
      <Get
        path={`/projects/${match.params.id}`}
        resolve={async project => {
          const data = project;
          data.type = this.getType(data);
          data.isEditable = Boolean(
            g(project, "author", []).find(author => author.id === user.id) &&
              project.state === g(projectStates, "proposal.name", "")
          );
          data.isPublicProject = Boolean(!g(project, "author", []).find(author => author.id === user.id));
          data.acceptedHandshakes = g(project, "handshakes", []).filter(
            ({ state }) => g(handshakeStates, "accepted.name", "") === state
          );
          data.projectChecklist =
            g(projectStates, "inProgress.name", "") === project.state
              ? await WizardService.getProjectChecklist(project.id)
              : [];
          data.projectChecklistCompleted = g(project, "projectChecklist", [])
            .filter(pc => pc.deliveredAt)
            .map(pc => g(pc, "itemType.id", ""));
          data.description = g(project, "bibliographyDescription", "")
            ? g(project, "bibliographyDescription", "")
            : g(project, "annotation", "");
          if (g(project, "author", []).find(author => author.id === user.id)) {
            const handshakes = await WizardService.getProjectHandshakes(project.id);
            data.handshakesBySubroleId = (handshakes || []).reduce((res, hs) => {
              const ids = res[hs.subrole.id] || [];
              return {
                ...res,
                [hs.subrole.id]: [...ids, hs]
              };
            }, {});
            data.handshakesByIds = (handshakes || []).reduce(
              (res, hs) => ({
                ...res,
                [hs.id]: hs
              }),
              {}
            );
          }
          if (project && project.rewards) {
            const filteredRewards = project.rewards.filter(r => r.physical === 1 && r.state === "active");
            data.price = filteredRewards.length === 1 ? Math.round(filteredRewards[0].price) : null;
          }
          dataLayer.push({
            event: "viewContent",
            content_ids: [data.id],
            value: data.price
          });
          return data;
        }}
      >
        {(project, { loading: requestLoading }, { refetch }) => {
          if (requestLoading) {
            return <Loader />;
          }

          let metaDescription = "";
          if (project.bibliographyDescription) {
            metaDescription = project.bibliographyDescription;
          } else if (project.annotation) {
            metaDescription = project.annotation;
          }

          dataLayer.push({
            event: "productClick",
            ecommerce: {
              detail: {
                products: [
                  {
                    name: project.name,
                    id: project.id,
                    price: project.price,
                    brand: project.authorName,
                    category: g(project, "genres[0].name", "")
                  }
                ]
              }
            }
          });

          SearchStorageService.clearSearchExpression();

          return (
            <Screen footer={isEmpty(user) ? null : <Footer />} header={<Header {...this.props} />}>
              <ShowModal history={history} location={location} project={project} />
              {
                <Helmet>
                  <head prefix="og: http://ogp.me/ns# book: http://ogp.me/ns/book#" />
                  <meta content="book" property="og:type" />
                  <meta content="cs_CZ" property="og:locale" />
                  <meta content={project.name} property="og:title" />
                  <meta content={project.name} name="twitter:title" />
                  <meta content={window.location.href} property="og:url" />
                  <meta content={window.location.href} name="twitter:url" />
                  <meta content={metaDescription} property="og:description" />
                  <meta content={metaDescription} name="twitter:description" />
                  {!isEmpty(project) && !isEmpty(project.files) ? (
                    <meta
                      content={project.files
                        .filter(file => g(file, "type", "") === "image-header")
                        .map(file => file.url)}
                      property="og:image"
                    />
                  ) : (
                    ""
                  )}
                  {!project.files && project.coverPhoto && <meta content={project.coverPhoto} property="og:image" />}
                  {!isEmpty(project) && !isEmpty(project.files) && (
                    <meta
                      content={project.files
                        .filter(file => g(file, "type", "") === "image-header")
                        .map(file => file.url)}
                      name="twitter:image"
                    />
                  )}
                  {!project.files && project.coverPhoto && <meta content={project.coverPhoto} name="twitter:image" />}
                  {!project.files && !project.coverPhoto && (
                    <meta content="https://pointa.cz/images/logo1200x630.png" property="og:image" />
                  )}
                  {!project.files && !project.coverPhoto && (
                    <meta content="https://pointa.cz/images/logo1200x630.png" name="twitter:image" />
                  )}
                  {project.bibliographyIsbn ? <meta content={project.bibliographyIsbn} property="books:isbn" /> : ""}
                  {project.authorName && <meta content={project.authorName} property="books:author" />}
                  {!isEmpty(g(project, "genres", [])) &&
                    g(project, "genres", [])
                      .filter(gen => !isEmpty(gen))
                      .map(genre => <meta key={genre.id} content={genre.name} property="book:tag" />)}
                  {!isEmpty(g(project, "tags", [])) &&
                    g(project, "tags", [])
                      .filter(t => !isEmpty(t))
                      .map(tag => <meta key={tag.id} content={tag.name} property="book:tag" />)}
                </Helmet>
              }
              {dynamicTitle(
                project.name,
                project.description,
                location.pathname,
                g(project, "coverPhoto", false),
                g(project, "tags", [])
                  .filter(tag => !isEmpty(tag))
                  .map(tag => tag.name)
              )}
              {this.state.shouldShowSuccessTopBar && <SuccessTopBar />}
              {project.type === "financing" && <FinancingState project={project} scrollToMyRef={this.scrollToMyRef} />}
              {project.type !== "released" &&
                project.type !== "inProgress" &&
                project.type !== "making" &&
                project.type !== "waitingForPresale" &&
                project.moonTarget === null && <NonFinancingState project={project} />}
              {project.type === "released" && <ReleasedState project={project} />}
              {project.type === "inProgress" && <ProgressState project={project} />}
              {(project.type === "making" || project.type === "waitingForPresale") && (
                <ProposalState project={project} />
              )}
              {project.isEditable && project.state === g(projectStates, "proposal.name", "") && (
                <EditableState project={project} />
              )}
              {project.state === g(projectStates, "proposal.name", "") ||
                (project.state === g(projectStates, "inProgress.name", "") && (
                  <div className={be(MODULE_NAME, "spacing", "xl")} />
                ))}
              <Tabs project={project} refetch={refetch} scrollToMyRef={this.scrollToMyRef} />
              {project.type === "financing" && <FinancingStateRewards myRef={this.myRef} project={project} />}
              <div className="row">
                <div className="col-sm">
                  {isEmpty(user) && (
                    <div className={be(MODULE_NAME, "footer")}>
                      <div className="row">
                        <div className="col-md-6">
                          <Button big inverted to="/register/author">
                            Vydej také vlastní knížku
                          </Button>
                        </div>
                        <div className="col-md-6 mt-4 mt-md-0">
                          <Button big invertedPrimary to="/register/contributor">
                            Staň se kolegou
                          </Button>
                        </div>
                      </div>
                      <Footer transparent />
                    </div>
                  )}
                </div>
              </div>
              <div className="clearfix" />
            </Screen>
          );
        }}
      </Get>
    );
  }
}

ProjectDetail.propTypes = {
  bindings: PropTypes.array.isRequired,
  dispatch: PropTypes.func.isRequired,
  genres: PropTypes.object.isRequired,
  handshakeStates: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  project: PropTypes.object.isRequired,
  projectStates: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  tips: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  rewards: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  const project = WizardDuck.getDetail(state);
  return {
    user: UserDuck.getUser(state),
    handshakeStates: EnumsDuck.getHandhakeStates(state),
    genres: EnumsDuck.getGenres(state),
    bindings: EnumsDuck.getBindings(state),
    project,
    rewards: WizardDuck.getRewards(state),
    files: project.state === "Financování" && project.moonTarget ? WizardDuck.getIntroImage(state) : null,
    introAnnotation: WizardDuck.getIntroAnnotation(state),
    video: WizardDuck.getIntroVideo(state),
    loading: WizardDuck.isLoadingDetail(state),
    tips: EnumsDuck.getTips(state),
    projectStates: EnumsDuck.getProjectStates(state)
  };
};

export default compose(translate("translations"), connect(mapStateToProps))(ProjectDetail);
